import React, { useState, useEffect, useContext } from 'react';
import OptionBox from './OptionBox';
import toggleOptionsIcon from "../icons/tool-01.svg"
import { GlobalContext } from '../App';

const ToggleOptions = () => {
    const { isPremium, knowledge, setKnowledge } = useContext(GlobalContext);
    const options = Object.keys(knowledge?.options || {});

    const [toggleStates, setToggleStates] = useState({});

    useEffect(() => {
        // This will run when 'knowledge.options' changes
        const updatedToggleStates = options.reduce((states, option) => {
            states[option] = knowledge.options[option];
            return states;
        }, {});

        setToggleStates(updatedToggleStates);
    }, [knowledge.options]);

    const handleOptionClick = (optionName) => {
        if (isPremium) {
            setToggleStates(prevStates => {
                const newStates = { ...prevStates, [optionName]: !prevStates[optionName] };
                setKnowledge(prevKnowledge => ({ ...prevKnowledge, options: newStates }));
                return newStates;
            });
        } else {
            alert("Upgrade to premium from the button in the usage section to use these features.")
        }
    };

    function formatOptionName(optionName) {
        return optionName
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (
        <div className="bg-content space-y-2 bg-[#333] p-5 rounded-md">
            <div className="items-center flex justify-left gap-1.5">
                <img
                    loading="lazy"
                    src={toggleOptionsIcon}
                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                />
                <h2 className="headerText leading-5 whitespace-normal my-auto">TOGGLE OPTIONS</h2>
            </div>
            <p className="subheaderText mb-4 pb-2">Options that you can toggle.</p>
            {options.map((optionName) => (
                <OptionBox
                    optionToggled={toggleStates[optionName]}
                    key={optionName}
                    optionName={formatOptionName(optionName)}
                    onOptionClick={() => handleOptionClick(optionName)}
                />
            ))}
        </div>
    );
};

export default ToggleOptions;
