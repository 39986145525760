import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

function PremiumOption(props) {
    const { name, descriptions, priceUSD, apiKey } = props;
    const [showPayPalButtons, setShowPayPalButtons] = useState(false);

    const createSubscription = (data, actions) => {
        return actions.subscription.create({
            plan_id: 'P-3A3585759J425492MMQJMW6Y',
            custom_id: apiKey
        });
    };

    const onApprove = (data, actions) => {
        return actions.subscription.capture().then((details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
        });
    };

    const handleChooseClick = () => {
        setShowPayPalButtons(true);
    };

    return (
        <div className="bg-white flex flex-col rounded-3xl px-6 md:px-5 items-left justify-left w-60">
            <div className="mt-6 text-2xl font-bold text-stone-800 leading-8">
                {name}
            </div>
            {
                descriptions.map((description, index) => (
                    <div key={index} className="flex items-left gap-3 mt-6">
                        <img
                            loading="lazy"
                            // You might want to change the image source based on the description or index
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c0ffa8b2-ca69-4859-8a62-453c971081b9?"
                            className="w-4 h-4 object-contain"
                        />
                        <div className="text-base text-stone-800 leading-5">
                            {description}
                        </div>
                    </div>
                ))
            }
        <div className="flex flex-col justify-bottom items-bottom">
            <div className="flex items-center justify-center mt-20 md:mt-10">
                <div className="text-2xl font-bold text-stone-800 leading-8">
                    <span>$</span> <span className="font-bold">{priceUSD === 0 ? "Free" : priceUSD}</span>
                </div>
                <div className="subheaderText mt-3 text-base text-stone-800 leading-5">
                    /month
                </div>
            </div>
            <div className="mt-3.5 mb-6 flex justify-center">
                {priceUSD > 0 && showPayPalButtons && (
                    <PayPalScriptProvider options={{ 'client-id': 'AdZt78IWpIBHNPzIngYL1GmwlNDtN2sjITP4xRNCAO3plB2fyOZ9wok5Cm12s5uvUUMA1BZhD80XDBrA', 'vault': true, 'intent': 'subscription' }}>
                        <PayPalButtons
                            createSubscription={createSubscription}
                            onApprove={onApprove}
                            style={{ layout: 'vertical' }}
                        />
                    </PayPalScriptProvider>
                )}
                {priceUSD > 0 && !showPayPalButtons && (
                    <button className="w-32 transition-all duration-300 ease-in-out transform hover:scale-105 rounded-lg bg-zinc-100 py-5 text-center text-base font-bold text-orange-500 leading-5" onClick={handleChooseClick}>
                        Choose
                    </button>
                )}
                {priceUSD == 0 && (
                    <div className="w-32 rounded-lg bg-zinc-100 py-5 text-center text-base font-bold text-orange-500 leading-5">
                        Free
                    </div>
                )}
            </div>
        </div>
    </div>
  );
}

export default PremiumOption;