export const MIN_BOTNAME = 3;
export const MAX_BOTNAME = 16;
export const MAX_BOTPERSONALITY = 100;

export const MAX_LINK_LENGTH = 32;

export const MAX_FEATURES = 100;

export const MAX_FEATURE_NAME_LENGTH = 32;
export const MAX_FEATURE_DESCRIPTION_LENGTH = 500;
export const MAX_FEATURE_COMMANDNAME_LENGTH = 32;
export const MAX_FEATURE_COMMANDDESCRIPTION_LENGTH = 100;

export const PUBLISH_INFO = "Click to publish. IMPORTANT: Ensure your plugin version is 0.6.0 or higher!"