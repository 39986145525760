import * as React from "react";

function PopupAPIKey(props) {
  const { apiKey } = props;
  // Handler for the cancel action
  function handleClose() {
      // Implement what should happen when canceled

      // Optionally use a callback prop to inform the parent component
      if (props.onClose) {
          props.onClose();
      }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
    // You can also implement a toast or alert to notify the user that the text was copied
  };
  
    return (
      <div className="z-10 fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-5">
        <div className="bg-white rounded shadow-lg p-6 w-full max-w-lg">
          <div className="text-xl font-medium text-gray-800 leading-8 tracking-normal mt-4">
            API Key
          </div>
          <div className="text-sm text-neutral-600 leading-5 tracking-wide mt-4 text-left">
            Place in your config.yml if it's not already there.
            <br />
            Keep your API key secret and do not share it with anyone.
          </div>
          <div className="flex items-left justify-center bg-zinc-100 w-full mt-4 px-4 py-6 rounded-md">
            <div className="text-base text-gray-800 leading-6 grow">
              {apiKey}
            </div>
            <button onClick={copyToClipboard} className="ml-4">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1db60dcd-7443-4a57-a525-3205d4492ee4?"
                className="w-6 h-6"
                alt="Copy"
              />
            </button>
          </div>
          <button onClick={handleClose} className="text-sm font-medium text-gray-800 leading-6 tracking-wide uppercase mt-9 mb-3.5">
            Close
          </button>
        </div>
      </div>
    );
}

export default PopupAPIKey;