import * as React from "react";

function Popup(props) {
    // Handler for the confirm action
    function handleConfirm() {
        // Implement what should happen when confirmed

        // Optionally use a callback prop to inform the parent component
        if (props.onConfirm) {
            props.onConfirm();
        }
    }

    // Handler for the cancel action
    function handleCancel() {
        // Implement what should happen when canceled

        // Optionally use a callback prop to inform the parent component
        if (props.onCancel) {
            props.onCancel();
        }
    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded shadow-lg p-6 w-full max-w-md">
                <div className="text-xl text-gray-800 font-medium mb-4">
                    Confirmation
                </div>
                <div className="text-sm text-gray-600 mb-8">
                    {props.description}
                </div>
                <div className="flex justify-between">
                    <button onClick={handleCancel} className="text-gray-800 text-sm font-medium uppercase tracking-wide">
                        Cancel
                    </button>
                    <button onClick={handleConfirm} className="text-green-600 text-sm font-medium uppercase tracking-wide">
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}
  

export default Popup;