import React, { useState, useContext } from "react";
import { GlobalContext } from "../App";

import { MIN_BOTNAME, MAX_BOTNAME, MAX_BOTPERSONALITY } from "./constants";

function BotSection(props) {
    const { knowledge, setKnowledge, botName, setBotName, botPersonality, setBotPersonality } = useContext(GlobalContext);

    const [expanded, setExpanded] = useState(false);

    const handleDropdownClick = () => {
      setExpanded(!expanded);
    };

    const handleBotNameChange = (newText) => {
        if (newText.length > MAX_BOTNAME){
            alert(`Bot name must be between ${MIN_BOTNAME} and ${MAX_BOTNAME} characters long.`)
            return;
        }

        setBotName(newText);
        setKnowledge((prevKnowledge) => ({ ...prevKnowledge, bot_name: newText }));
    }

    const handleBotPersonalityChange = (newText) => {
        if (newText.length >= MAX_BOTPERSONALITY){
            alert("Bot personality must be less than or equal to 100 characters long.")
            return;
        }

        setBotPersonality(newText);
        setKnowledge((prevKnowledge) => ({ ...prevKnowledge, bot_prompt: newText }));
    }

    if(botName === undefined || botName == ""){
      setBotName("");
    }

    if(botPersonality === undefined || botPersonality == ""){
      setBotPersonality("");
    }

  return (
    <section className="bg-content pb-6 items-stretch bg-content flex justify-between gap-5 px-6 rounded-xl max-md:px-5">
      <article className="mt-6">
        <div className="gap-5 flex max-md:flex-col max-md:gap-0">
          <div className="flex flex-col items-stretch w-3/12 max-md:w-full">
            <div className="items-stretch flex flex-col max-md:mt-4">
              <div className="items-stretch flex flex-col">
                <div className="items-stretch flex justify-left gap-1.5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6584a11b-2e2d-4d18-a0e5-c7ff8a3bede0?apiKey=ce7d3297d88941ed819ad943a2750d93&"
                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                    alt="Bot Avatar"
                  />
                  <div className="headerText leading-5 self-center whitespace-nowrap my-auto">
                    BOT NAME
                  </div>
                </div>
                <div className="subheaderText leading-4 mt-1.5">
                  Name for chatbot interactions.
                </div>
              </div>
              <div className="items-stretch flex flex-col mt-4 rounded">
                <input 
                    placeholder="Jeff" 
                    type="text" 
                    value={botName}
                    onChange={(e) => handleBotNameChange(e.target.value)}
                    maxLength={MAX_BOTNAME}
                    className="text-white bg-black text-base leading-6 whitespace-nowrap rounded border p-4 bg-transparent rounded-md border border-[color:var(--m-3-sys-light-outline,#79747E)]" />
                <div className="limitText leading-4 mt-1.5">
                    {botName.length}/{MAX_BOTNAME}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-9/12 ml-5 max-md:w-full max-md:ml-0">
            <div className="flex grow flex-col">
              <div className="items-stretch flex w-[500px] max-w-full flex-col">
                <div className="items-stretch flex justify-left gap-1.5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/bc08e243-1a55-49fa-b646-0b9198259836?apiKey=ce7d3297d88941ed819ad943a2750d93&"
                    className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                    alt="Bot Personality Avatar"
                  />
                  <div className="headerText leading-5 self-center whitespace-nowrap my-auto">
                    BOT PERSONALITY
                  </div>
                </div>
                <div className="subheaderText whitespace-nowrap mt-2">
                  Describe the chatbot personality.
                </div>
              </div>
              <div className="flex flex-col items-stretch self-stretch mt-8 rounded max-w-full">
                <input
                    type="text"
                    value={botPersonality}
                    onChange={(e) => handleBotPersonalityChange(e.target.value)}
                    maxLength={MAX_BOTPERSONALITY}
                    placeholder="You are a helpful and quirky chatbot. Concisely answer questions with an excited personality!"
                    className="text-sm w-full bg-black text-white text-base leading-6 whitespace-nowrap rounded border p-4 bg-transparent rounded-md border border-[color:var(--m-3-sys-light-outline,#79747E)]"
                />
                <div className="limitText leading-4 mt-1.5">
                    {botPersonality.length}/{MAX_BOTPERSONALITY}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e893152b-5249-440c-9add-b8d13025709d?apiKey=ce7d3297d88941ed819ad943a2750d93&"
        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full mt-6 self-start"
        alt="Additional Image"
        type="button"
        onClick={handleDropdownClick}
      />
    </section>
  );
}

export default BotSection;