import React, { useState } from "react";
import PremiumOption from "./PremiumOption";

function PremiumPage({ onClose, api_key }) {

  return (
    <>
      <div className="z-10 fixed inset-0 bg-orange-500 flex justify-center items-center p-5 animate-fadeIn delay-1000">
        <div className="relative bg-white rounded-3xl overflow-hidden shadow-lg w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d48550d-6e31-4035-a777-7f3dd0df52e1?"
            className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
          />
        </div>
      </div>
      <button 
        className="z-10 absolute top-0 left-0 m-4 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center" 
        onClick={() => onClose()}
      >
        X
      </button>
      <div className="z-10 flex flex-row justify-center flex-wrap gap-4">
        <PremiumOption name="Free" descriptions={["Limit of 15 replies per day", "Bot only scans messages containing its name keyword"]} priceUSD="0" apiKey={api_key}></PremiumOption>
        <PremiumOption name="Premium" descriptions={["250 replies per day", "Bot can scan ALL chat messages for players in need of help", "Ability to customize bot options", "Access to any experimental features before anyone else"]} priceUSD="4.99" apiKey={api_key}></PremiumOption>
      </div>
    </>
  );
}

export default PremiumPage;
