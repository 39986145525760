import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../App";
import featureNameIcon from "../icons/tag-02.svg"
import featureInfoIcon from "../icons/book-open-01.svg"
import featureCmdIcon from "../icons/keyboard-02.svg"
import listIcon from '../icons/list.svg';

import { MAX_FEATURES, MAX_FEATURE_NAME_LENGTH, MAX_FEATURE_DESCRIPTION_LENGTH, MAX_FEATURE_COMMANDNAME_LENGTH, MAX_FEATURE_COMMANDDESCRIPTION_LENGTH } from "./constants";

function FeatureComponent(props) {
    const { knowledge, setKnowledge } = useContext(GlobalContext);
    const features = knowledge.features;
    const { selectedFeature, setSelectedFeature } = useContext(GlobalContext);

    // Safety check for selectedFeatureIndex
    const selectedFeatureIndex = selectedFeature
        ? knowledge.features.findIndex(feature => feature.name === selectedFeature.name)
        : -1;
    const [newFeatureInput, setNewFeatureInput] = useState("");

    // Ensure commands list always has an empty command at the end for new input
    useEffect(() => {
        if (selectedFeatureIndex !== -1) {
            const commands = knowledge.features[selectedFeatureIndex].commands;
            const lastCommand = commands[commands.length - 1];

            if (lastCommand && (lastCommand.commandName.trim() !== '' || lastCommand.commandDescription.trim() !== '')) {
                const updatedKnowledge = { ...knowledge };
                updatedKnowledge.features[selectedFeatureIndex].commands.push({ commandName: "", commandDescription: "" });
                setKnowledge(updatedKnowledge);
            }
        }
    }, [selectedFeature, knowledge.features, selectedFeatureIndex]);
    
    const removeEmptyCommandPairs = () => {
        const updatedKnowledge = { ...knowledge };
        const commands = updatedKnowledge.features[selectedFeatureIndex].commands;
    
        if (commands.length > 1) {
            // Remove command pairs where both name and description are empty, except the last one
            const filteredCommands = commands.filter((command, index) => {
                return index === commands.length - 1 || command.commandName.trim() !== '' || command.commandDescription.trim() !== '';
            });
    
            updatedKnowledge.features[selectedFeatureIndex].commands = filteredCommands;
            setKnowledge(updatedKnowledge);
        }
    };    

    const handleCommandNameChange = (commandIndex, value) => {
        const updatedKnowledge = { ...knowledge };
        updatedKnowledge.features[selectedFeatureIndex].commands[commandIndex].commandName = value;
        setKnowledge(updatedKnowledge);
    
        removeEmptyCommandPairs(); // Call the function to remove empty pairs
    };
    
    const handleCommandDescriptionChange = (commandIndex, value) => {
        const updatedKnowledge = { ...knowledge };
        updatedKnowledge.features[selectedFeatureIndex].commands[commandIndex].commandDescription = value;
        setKnowledge(updatedKnowledge);
    
        removeEmptyCommandPairs(); // Call the function to remove empty pairs
    };
    
    // Function to update a feature
    const updateFeature = (featureIndex, updatedFeature) => {
        setKnowledge(prevKnowledge => {
            const updatedFeatures = [...prevKnowledge.features];
            updatedFeatures[featureIndex] = updatedFeature;
            return { ...prevKnowledge, features: updatedFeatures };
        });
    };

    const handleFeatureNameChange = (value) => {
        if (selectedFeatureIndex !== -1) {
            // Check for duplicate name
            const isDuplicate = knowledge.features.some((feature, index) => 
                feature.name.trim().toLowerCase() === value.trim().toLowerCase() && index !== selectedFeatureIndex
            );
    
            if (isDuplicate) {
                alert('A feature with this name already exists.');
                return;
            }

            const updatedFeature = { ...knowledge.features[selectedFeatureIndex], name: value };
            setSelectedFeature(updatedFeature);
            updateFeature(selectedFeatureIndex, updatedFeature);
        }
    };
    
    
    const handleFeatureDescriptionChange = (value) => {
        if (selectedFeatureIndex !== -1) {
            const updatedFeature = { ...knowledge.features[selectedFeatureIndex], description: value };
    
            // Update the selected feature in the context
            setSelectedFeature(updatedFeature);
    
            // Update the knowledge state
            updateFeature(selectedFeatureIndex, updatedFeature);
        }
    };
    

    function updateFeatureAndKnowledge() {
        const selectedFeatureIndex = knowledge.features.findIndex(feature => feature.name === selectedFeature.name);
    
        if (selectedFeatureIndex === -1) {
            console.error('Selected feature not found');
            return;
        }
    
        const updatedKnowledge = { ...knowledge };
        const selectedFeatureCommands = updatedKnowledge.features[selectedFeatureIndex].commands;
    
        // Check the last command's name to see if it is not empty
        const lastCommand = selectedFeatureCommands[selectedFeatureCommands.length - 1];
        if (lastCommand.commandName.trim() !== '') {
            // Add a new empty command if the last command name is not empty
            selectedFeatureCommands.push({ commandName: "", commandDescription: "" });
        }
    
        // Update the knowledge state with the new commands
        setKnowledge(updatedKnowledge);
    }
    

    function handleButtonClick(text) {
        if(features.length >= MAX_FEATURES){
            alert(`You have reached the maximum number of features (${MAX_FEATURES}).`);
            return;
        }
        
        // Check for duplicate name
        const isDuplicate = features.some(feature => feature.name.trim().toLowerCase() === text.trim().toLowerCase());
    
        if (isDuplicate) {
            alert('A feature with this name already exists.');
            return;
        }
    
        const newFeature = {
            name: text,
            description: "",
            commands: [{ commandName: "", commandDescription: "" }]
        };
    
        setKnowledge(prevKnowledge => ({
            ...prevKnowledge,
            features: [...prevKnowledge.features, newFeature]
        }));
    
        setNewFeatureInput("");
        setSelectedFeature(newFeature);
    }
    

    const handleDeleteFeature = (featureToDelete) => {
        if (window.confirm(`Are you sure you want to delete the feature "${featureToDelete.name}"?`)) {
            setKnowledge(prevKnowledge => ({
                ...prevKnowledge,
                features: prevKnowledge.features.filter(feature => feature !== featureToDelete)
            }));
    
            // // If the deleted feature was selected, deselect it
            // if (selectedFeature === featureToDelete) {
            //     setSelectedFeature(features[0]);
            // }
        }
    };


    useEffect(() => {
        const features = knowledge.features;
        const shouldCreateDefaultFeature = features.length === 0 || 
                                           (features[0].name.trim() === "" && features[0].description.trim() === "");

        if (shouldCreateDefaultFeature) {
            const defaultFeature = {
                name: "My first feature",
                description: "",
                commands: [{ commandName: "", commandDescription: "" }]
            };

            setKnowledge(prevKnowledge => ({
                ...prevKnowledge,
                features: [defaultFeature, ...prevKnowledge.features.filter(f => f !== features[0])]
            }));

            setSelectedFeature(defaultFeature);
        }else{
        //    setSelectedFeature(features[0]);
        }
    }, [knowledge.features]); // Depend on knowledge.features to re-run when features change

    useEffect(() => {
        if(knowledge.features.length >= 1){
            setSelectedFeature(knowledge.features[knowledge.features.length - 1]);
        }
    }, []);


  return (
    
    <div className="bg-content features-container">
    <section style={{ maxWidth: '250px' }} className="max-w-xs p-6 rounded-md overflow-hidden">
        <div className="items-center flex justify-left gap-1.5">
          <img
            loading="lazy"
            src={listIcon}
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
          />
          <h2 className="headerText">FEATURE LIST</h2>
        </div>
        <div className="subheaderText pt-2 pb-4">
          Your server's features, categorized however you'd like.
        </div>
            <div className="custom-scroll overflow-auto py-0 max-h-[25rem] space-y-2">
                <div className="relative mb-2 flex items-center rounded-md border border-[color:var(--m-3-sys-light-outline,#79747E)]">
                    <input
                        type="text"
                        placeholder="Feature name"
                        className="w-full pl-4 py-2 text-white bg-transparent"
                        value={newFeatureInput}
                        onChange={(e) => setNewFeatureInput(e.target.value)}
                    />
                    <button
                        className="flex items-center justify-center px-3 rounded-r-md"
                        onClick={() => {
                        if (newFeatureInput.trim() !== "") {
                            handleButtonClick(newFeatureInput);
                        }
                        }}
                    >
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/613f9654-018f-4f85-b2ff-c1f79cc1adcb?"
                        className="w-8 h-8"
                        alt="Add"
                    />
                </button>
            </div>
        
            <div className="custom-scroll py-0 max-h-[25rem] overflow-auto space-y-2">
                {features.map((feature, index) => (
                    <div key={index} className="relative my-2">
                    <button
                        className="text-left text-white text-base leading-6 whitespace-nowrap rounded-md w-full py-2 px-4"
                        style={{ borderRadius: '4px', background: '#332A24' }}
                        onClick={() => setSelectedFeature(feature)}
                    >
                        {feature.name}
                    </button>
                    {selectedFeature === feature && (
                        <button
                            onClick={() => handleDeleteFeature(feature)}
                            className="absolute top-0 right-0 mt-1 mr-2 text-white rounded-full p-1 flex items-center justify-center"
                            aria-label={`Delete ${feature.name}`}
                            style={{ borderRadius: '4px' }}
                        >
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0e6f09bb-1d11-4462-bc17-c5025f23ae5c?"
                            className="w-6 h-6"
                            alt="Delete"
                        />
                        </button>
                    )}
                    </div>
                ))}
            </div>
        </div>
      </section>
    <div className="flex-grow p-4 rounded-lg space-y-4">
        
        <div>
        {/* Removed "space-y-2" and added "mb-2" to this div to add margin-bottom */}
        <div className="items-center flex justify-left gap-1.5 mb-2">
            <img
            loading="lazy"
            src={featureNameIcon}
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
            />
            <label htmlFor="featureName" className="headerText leading-5 whitespace-nowrap my-auto block text-sm text-gray-400">
            FEATURE NAME
            </label>
        </div>
        {/* This already had "mb-2" to add margin-bottom */}
        <div className="subheaderText mb-2">
            You can update the feature name anytime in this text field.
        </div>
        {/* No change here, input will take natural space */}
        <input
            type="text"
            id="featureName"
            value={selectedFeature.name}
            onChange={(e) => handleFeatureNameChange(e.target.value)}
            placeholder="Feature name"
            className="bg-transparent w-full text-white rounded p-2 rounded-md border border-[color:var(--m-3-sys-light-outline,#79747E)]"
            maxLength={MAX_FEATURE_NAME_LENGTH}
        />
        {/* Removed "space-y-2" effect by adjusting padding/margin if necessary. In this case, it looks like no negative padding is required. */}
            <div className="limitText leading-4 pt-1.5">
                {selectedFeature.name.length}/{MAX_FEATURE_NAME_LENGTH}
            </div>
        </div>
      <div>
    <div className="items-center flex justify-left gap-1.5 mb-2">
        <img
        loading="lazy"
        src={featureInfoIcon}
        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
        />
        <label htmlFor="featureInfo" className="headerText leading-5 whitespace-nowrap my-auto block text-sm text-gray-400">
        FEATURE INFORMATION
        </label>
    </div>
        <div>
        <div className="subheaderText mb-2">
        Add any information that can help the chatbot to give a better response.
        </div>
            <textarea
                id="featureInfo"
                rows="4"
                value={selectedFeature.description}
                onChange={(e) => handleFeatureDescriptionChange(e.target.value)}
                placeholder="Describe your feature any way you want. Don't worry about formatting or grammar."
                className="custom-scroll overflow-auto w-full text-white rounded p-2 bg-transparent rounded-md border border-[color:var(--m-3-sys-light-outline,#79747E)]"
                maxLength={MAX_FEATURE_DESCRIPTION_LENGTH}
            />
            <div className="limitText leading-4 pt-0">
                {selectedFeature.description.length}/{MAX_FEATURE_DESCRIPTION_LENGTH}
            </div>
        </div>
      <div className="space-y-2">
        <div className="items-center flex justify-left gap-1.5">
            <img
                loading="lazy"
                src={featureCmdIcon}
                className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
            />
            <label htmlFor="featureCommand" className="headerText leading-5 whitespace-nowrap my-auto block text-sm text-gray-400">
            FEATURE COMMANDS INFO
            </label>
        </div>
        <div className="subheaderText">
            Explain how to use any related commands here.
        </div>

        <section className="custom-scroll max-h-[10rem] overflow-auto">
            <div className="flex flex-col space-x-0 space-y-2">
                {selectedFeatureIndex !== -1 && knowledge.features[selectedFeatureIndex].commands.map((command, index) => (
                    <div key={index} className="flex items-center space-x-2">
                        <input
                            type="text"
                            id={`featureCommand-${index}`}
                            value={command.commandName}
                            onChange={(e) => handleCommandNameChange(index, e.target.value)}
                            className="flex-1 text-white rounded p-2 bg-transparent rounded-md border border-[color:var(--m-3-sys-light-outline,#79747E)]"
                            placeholder="Command"
                            maxLength={MAX_FEATURE_COMMANDNAME_LENGTH}
                        />
                        <input
                            type="text"
                            id={`featureDescription-${index}`}
                            value={command.commandDescription}
                            onChange={(e) => handleCommandDescriptionChange(index, e.target.value)}
                            className="flex-1 text-white rounded p-2 bg-transparent rounded-md border border-[color:var(--m-3-sys-light-outline,#79747E)]"
                            placeholder="Description"
                            maxLength={MAX_FEATURE_COMMANDDESCRIPTION_LENGTH}
                        />
                    </div>
                ))}
                <button onClick={updateFeatureAndKnowledge} className="text-white bg-yellow-500 rounded p-2">
                    Add Command
                </button>
            </div>
        </section>
      </div>

    </div>
    </div>
    </div>
  );
}


export default FeatureComponent;
