import * as React from "react";

function Snackbar(props) {
    const { text } = props;
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        setIsVisible(true);
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, [text]);

    return (
        <div className={`fixed bottom-0 left-0 mb-4 ml-4 z-10 transition-transform duration-300 ${isVisible ? "translate-y-0" : "-translate-y-full"} text-stone-900 text-sm leading-5 items-stretch rounded shadow bg-amber-500 px-4 py-3.5`}>
            {text}
        </div>
    );
}

export default Snackbar;
