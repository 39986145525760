import * as React from "react";

function PopupNoConnection(props) {
  function handleClose() {
    const popupElement = document.querySelector('.popup-no-connection');
    popupElement.classList.add('animate-fadeOut');

    setTimeout(() => {
      popupElement.remove();
    }, 1000); // Match this timeout with the duration of the fade-out animation
  }

  return (
    <div className="z-10 fixed inset-0 bg-orange-500 flex justify-center items-center p-5 animate-fadeIn delay-1000 popup-no-connection">
      <div className="relative bg-white rounded-3xl overflow-hidden shadow-lg max-w-lg w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d48550d-6e31-4035-a777-7f3dd0df52e1?"
          className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
        />
        <div className="relative p-8 text-center">
          <h2 className="text-3xl text-stone-800 font-bold mb-4">
            Dashboard Not Loaded
          </h2>
          <p className="text-left text-sm text-stone-800 mb-6">
            You are not using a valid dashboard link, or are refreshing too often.<br />
            Run the <span className="font-bold">/chatbotpro dashboard</span> command in-game to generate a link.<br />
            <br/>
            You may close this window, but changes won't be saved.
            <br/>
            <br/>
            <br/>
          </p>
          <button onClick={handleClose} className="text-base font-bold text-orange-500 bg-zinc-100 px-5 py-3.5 rounded-md">
            Close Window
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupNoConnection;
