// NavigationBar.js

import React, {useState, useContext} from 'react';
import Popup from './Popup';
import PopupAPIKey from './PopupAPIKey';
import PremiumPage from './PremiumPage';
import logoIcon from "../icons/Logo.svg"

import { GlobalContext } from '../App';
import { handlePublish } from '../utils/APICalls';
import { PUBLISH_INFO } from './constants';


function NavigationBar(props) {
  const { knowledge, setShowSnackbar, setSnackbarText } = useContext(GlobalContext);
  const [publishInfo, setPublishInfo] = useState(PUBLISH_INFO);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupAPIKey, setShowPopupAPIKey] = useState(false);
  const [showPopupPremium, setShowPopupPremium] = useState(false);

  // Function to toggle the popup visibility
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Function to toggle the popup visibility
  const togglePopupAPIKey = () => {
    setShowPopupAPIKey(!showPopupAPIKey);
  };

  const togglePopupPremium = () => {
    setShowPopupPremium(!showPopupPremium);
  }

  return (
    <>
    <nav className="bg-content navigationBar">
      <div className="items-stretch flex justify-between gap-5 px-16 max-md:flex-wrap max-md:px-5 p-2">
        <div className="items-stretch flex justify-between space-x-2">
          <img
            loading="lazy"
            src={logoIcon}
            className="aspect-square object-contain object-center w-[38px] overflow-hidden shrink-0 max-w-full"
          />
          <div className="text-zinc-400 text-lg font-bold leading-5 self-center grow whitespace-nowrap my-auto">
            CHATBOTPRO - Update jar to VERSION 0.6.0 or later to use.
          </div>
        </div>
        <div className="justify-between items-stretch flex p-2 space-x-4">
          <button onClick={togglePopupAPIKey} className="text-white text-base font-bold leading-5 whitespace-nowrap justify-right items-right rounded bg-amber-700 grow p-2.5">
            VIEW API KEY
          </button>
          <button onClick={togglePopup} className="text-white text-base font-bold leading-5 whitespace-nowrap justify-right items-right rounded bg-orange-500 grow p-2.5">
            PUBLISH CHANGES
          </button>
        </div>
      </div>
    </nav>

    {showPopup && 
      <Popup 
        description={publishInfo}
        onConfirm={async () => {
          setPublishInfo("Publishing...");
          const reply = await handlePublish(knowledge, setShowSnackbar, setSnackbarText); 
          // console.log("Showing Snackbar");
          // setShowSnackbar(true);
          // console.log("Setting Snackbar Text");
          // setSnackbarText("Published!");

          // check if reply is error

          if(!reply.error){
            setTimeout(() => {
              togglePopup();
              setPublishInfo(PUBLISH_INFO)
            }, 100);
          }

          setPublishInfo(reply.text);

        }} 
        onCancel={() => togglePopup()} 
      />
    }

    {showPopupAPIKey && 
      <PopupAPIKey 
        apiKey={props.apiKey}
        onClose={() => togglePopupAPIKey()}
      />
    }

    {showPopupPremium &&
      <PremiumPage
        onClose={() => togglePopupPremium()}
        apiKey={props.apiKey}
      />
    }
    </>
  );
}

export default NavigationBar;
