import React, { useState, useContext } from "react";
import { GlobalContext } from "../App";

import { MAX_LINK_LENGTH } from "./constants";

// Define your links as an array of objects
const iconMap = 
  {
    ip: "https://cdn.builder.io/api/v1/image/assets/TEMP/1cb20070-f097-4610-96d8-f11f3c203f09?apiKey=ce7d3297d88941ed819ad943a2750d93&",
    website: "https://cdn.builder.io/api/v1/image/assets/TEMP/e1e39286-a416-4e17-ab61-babeb4b96241?apiKey=ce7d3297d88941ed819ad943a2750d93&",
    store: "https://cdn.builder.io/api/v1/image/assets/TEMP/95369de5-d189-41e5-ad68-dfd5698af32c?apiKey=ce7d3297d88941ed819ad943a2750d93&",
    discord: "https://cdn.builder.io/api/v1/image/assets/TEMP/8fb39be2-e99f-487b-8914-7464d51fc528?apiKey=ce7d3297d88941ed819ad943a2750d93&",
    twitter: "https://cdn.builder.io/api/v1/image/assets/TEMP/1d17f995-daf8-4227-8e9e-d34504b32f88?apiKey=ce7d3297d88941ed819ad943a2750d93&",
  };

const placeholderMap = 
{
  ip: "Enter Server IP",
  website: "Enter Website Link",
  store: "Enter Shop Link",
  discord: "Enter Discord Link",
  twitter: "Enter Twitter Link",
};

function ServerLinks() {
  const { knowledge, setKnowledge } = useContext(GlobalContext);

  const handleServerLinkChange = (key, newText) => {
    // Update the specific link in the knowledge.links object
    const updatedLinks = {
      ...knowledge.links,
      [key]: newText
    };
  
    // Update the knowledge state with the new links object
    setKnowledge({ ...knowledge, links: updatedLinks });
  };
  

  return (
    <section className="bg-content p-5 rounded-xl">
      <div className="flex justify-left gap-1.5">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1e9b9e9a-0b35-4910-82e5-82baf21695ce?apiKey=ce7d3297d88941ed819ad943a2750d93&"
          className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
          alt="Server Logo"
        />
        <h2 className="headerText leading-5 whitespace-nowrap my-auto my-4">
          SERVER LINKS
        </h2>
      </div>
      <p className="subheaderText pt-2 mb-4 max-w-7/10xs">
        Your bot can automatically help players find your server's links.
      </p>
      {Object.entries(knowledge.links).map(([key, value], index) => (
        <div key={index} className="flex items-center my-2">
          <img src={iconMap[key]} className="w-6 h-6 mr-4" alt={key} />
          <input
            type="text"
            value={knowledge.links[key]}
            onChange={(e) => handleServerLinkChange(key, e.target.value)}
            maxLength={MAX_LINK_LENGTH}
            placeholder={placeholderMap[key]}
            className="flex-1 text-white bg-black rounded p-2 border border-zinc-500 bg-transparent rounded-md border border-[color:var(--m-3-sys-light-outline,#79747E)]"
          />
        </div>
      ))}
    </section>
  );
}

export default ServerLinks;
