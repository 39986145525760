// App.js
import React, { useState, useEffect } from 'react';
import PopupNoConnection from './components/PopupNoConnection.js';
import NavigationBar from './components/NavigationBar';
import ServerLinks from './components/ServerLinks';
import ToggleOptions from './components/ToggleOptions';
import UsageSection from './components/UsageSection';
import BotSection from './components/BotSection.js';
import FeatureComponent from './components/FeatureComponent.js';
import Snackbar from './components/Snackbar.js';
import './App.css';
import { parseDoc, convertToKnowledgeFormat } from './utils/APICalls.js';


export const GlobalContext = React.createContext();

const DEBUG = false;

function App() {

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  const [botName, setBotName] = useState("");
  const [botPersonality, setBotPersonality] = useState("");

  const [isPremium, setIsPremium] = useState(false);
  const [usageToday, setUsageToday] = useState(0);
  const [freeMaxDaily, setFreeMaxDaily] = useState(0);
  const [premiumMaxDaily, setPremiumMaxDaily] = useState(0);
  const [noConnection, setNoConnection] = useState(false);

  const [api_key, setApiKey] = useState("Dashboard is not connected. Type /chatbotpro dashboard in-game.");

  const [knowledge, setKnowledge] = useState({
    api_key: "",
    bot_name: "",
    bot_prompt: "",
    options: {
      disable_casual_conversation: false,
      scan_all_messages: false
    },
    links: {
      ip: "",
      website: "",
      store: "",
      discord: "",
      twitter: ""
    },
    features: [{
      name: "",
      description: "",
      commands: [
        { commandName: '', commandDescription: '' }
      ]
    }]
  });

  useEffect(() => {
    const fetchData = async () => {
      // Check if the window object is available
      if (typeof window !== "undefined") {
        const currentUrl = new URL(window.location.href);
        const queryParams = new URLSearchParams(currentUrl.search);
        
        const deeplink = queryParams.get('');
        const response = await parseDoc(deeplink);

        if(!response || response.error){
          setNoConnection(true);
          return;
        }

        if(response.api_key != ""){
          setBotName(response.doc.bot_name);
          setBotPersonality(response.doc.bot_prompt || "");

          setApiKey(response.api_key);
          setIsPremium(response.isPremium);
          setUsageToday(response.usageToday);
          setFreeMaxDaily(response.freeMaxDaily);
          setPremiumMaxDaily(response.premiumMaxDaily);

          setKnowledge(convertToKnowledgeFormat(response.doc, response.api_key, response.options));
        }else{
          setNoConnection(true);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // show snackbar when the snackbar text is updated
    if(snackbarText != ""){
      setShowSnackbar(true);
    }
  }, [snackbarText]);

  const [selectedFeature, setSelectedFeature] = useState({
    name: "",
    description: "",
    commands: new Map()
  });
  
  return (
    <GlobalContext.Provider value={{ selectedFeature, setSelectedFeature, knowledge, setKnowledge, api_key, showSnackbar, setShowSnackbar, snackbarText, setSnackbarText, isPremium, usageToday, freeMaxDaily, premiumMaxDaily, botName, setBotName, botPersonality, setBotPersonality }}>
      <div className="App bg-dark">
      {/* {showSnackbar && <Snackbar text={snackbarText} />} */}
      {!DEBUG && noConnection && <PopupNoConnection></PopupNoConnection>}
        <NavigationBar apiKey={api_key}/>
        <div className="content w-3/4 mx-auto">
          <div className="leftSide">
            <div className="dropdowns">
              <UsageSection />
              <BotSection />
            </div>
            <div className="features-container">
              <FeatureComponent />
            </div>
          </div>
          <div className="linksAndToggles">
            <ServerLinks />
            <ToggleOptions />
          </div>
        </div>
        {DEBUG && <text className="text-white">
          <pre>{(JSON.stringify(knowledge, null, 2))}</pre>
        </text>}
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
