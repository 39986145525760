import * as React from "react";

function OptionBox(props) {
  const { optionToggled, optionName, onOptionClick } = props; // Assuming you pass a click handler as a prop

  return (
    <div className="flex flex-col w-full px-4 py-3 bg-listing rounded-md items-center">
      <div className="flex w-full justify-between items-center gap-5">
        <div className="text-base leading-6 text-zinc-200">
          {optionName}
        </div>
        {/* This button is now clickable and always justified to the right */}
        <button
          className="flex justify-center items-center w-4 h-4 rounded-sm border-2 border-solid border-zinc-500"
          onClick={onOptionClick}
        >
          {optionToggled && 
            <img
              key={new Date()}
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/efe92fa6-c505-4d9e-b78d-30de360af688"
              className="w-8 h-8 object-cover object-center" // Adjusted classes for size
            />
          }
        </button>

      </div>
    </div>
  );
}


export default OptionBox;