import React, { useState, useContext } from "react";
import PremiumPage from "./PremiumPage";

import { GlobalContext } from "../App";

function UsageSection(props) {
  const { isPremium, usageToday, freeMaxDaily, premiumMaxDaily, api_key } = useContext(GlobalContext);

  // hardcode for now because too much bullshit going on
  const maxUsage = isPremium ? 250 : 15;
  const percentage = usageToday && maxUsage ? (usageToday / maxUsage) * 100 : 0;
  
  const [expanded, setExpanded] = useState(false);
  const [showPopupPremium, setShowPopupPremium] = useState(false);

  const handleDropdownClick = () => {
    setExpanded(!expanded);
  };

  const openPremiumPopup = () => {
    setShowPopupPremium(true);
  }

  const handleClosePremiumPage = () => {
    setShowPopupPremium(false);
  };

  return (
    <>
    <section className="bg-content items-stretch bg-content flex flex-col px-6 rounded-xl max-md:px-5">
      <div className="flex justify-between gap-5 mt-7">
        <div className="flex grow basis-[0%] flex-col items-stretch mt-1">
          <div className="flex justify-left gap-2.5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ae6f0ea-f53f-4b48-9a18-fa8aacedc66a?apiKey=ce7d3297d88941ed819ad943a2750d93&"
              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
              alt="Icon"
            />
            <div className="headerText leading-5 whitespace-nowrap my-auto">
              DAILY USAGE
            </div>
          </div>
          <div className="usageBarText whitespace-nowrap mt-8">
            Responses used today.
          </div>
        </div>
        <div className="flex grow basis-[0%] flex-col items-end">
          <div className="flex items-stretch justify-between gap-5">
            <button
              onClick={openPremiumPopup}
              className="text-white text-base font-bold leading-5 whitespace-nowrap justify-end items-stretch rounded bg-orange-500 grow px-2.5 py-1.5"
            >
              UPGRADE
            </button>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ff02efd-1cfd-4944-80ff-f3fcb857fbe1?apiKey=ce7d3297d88941ed819ad943a2750d93&"
              className="aspect-square object-contain object-center w-6 overflow-hidden self-center shrink-0 max-w-full my-auto"
              alt="Icon"
              type="button"
              onClick={handleDropdownClick}
            />
          </div>
          <div className="usageBarText leading-5 self-right whitespace-nowrap mt-8">
            {usageToday}/{maxUsage}
          </div>
        </div>
      </div>
      <div className="bg-stone-700 flex flex-col mt-1 mb-6 rounded-lg max-md:max-w-full">
        <div
            style={{ width: `${percentage}%` }}
            className="bg-orange-500 h-4 rounded-lg"
        />
        </div>
    </section>

    {showPopupPremium &&
        <PremiumPage 
        onClose={handleClosePremiumPage}
        apiKey={api_key}
      />
    }
    </>

  );
}

export default UsageSection;