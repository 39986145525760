import { useContext } from "react";
import { GlobalContext } from "../App";

const DOMAIN = "https://epjeff-controller.onrender.com/"
//const DOMAIN_LOCAL = "http://localhost:3001/"
// and also remove the max connection of 50 FROM CONECTOR

function convertToKnowledgeFormat(doc, the_api_key, the_options) {
    // Check for the presence of new or old version fields
    const isOldVersion = !('options' in doc);
  
    return {
      api_key: the_api_key, // Use old version field or default
      bot_name: doc.bot_name || "",
      bot_prompt: !isOldVersion ? doc.bot_prompt : "", // Use old version field or default
      options: !isOldVersion ? the_options : {
        disable_casual_conversation: false,
        scan_all_messages: false
      },
      links: doc.links || {
        ip: "",
        website: "",
        store: "",
        discord: "",
        twitter: ""
      },
      features: doc.features.map(feature => ({
        name: feature.name || "",
        description: feature.description || "",
        commands: feature.commands.map(command => ({
          commandName: !isOldVersion ? command.commandName : command.command || "",
          commandDescription: !isOldVersion ? command.commandDescription : command.description || ""
        }))
      })) || []
    };
}
  
const parseDoc = async (deeplink) => {
    if (!deeplink) {
      return false;
    }
  
    try {
      const response = await fetch(DOMAIN + 'dashboard/load', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          deeplink: deeplink,
        })
      });

      console.log(response)
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      console.log(responseData); // This should log the response data
      return responseData;       // This returns the response data to the caller
    } catch (error) {
      console.log('Error in parseDoc:', error);
      return false;
    }
  }

 const handlePublish = async (knowledge, setShowSnackbar, setSnackbarText) => {
  
  // if (publishText === "Publishing...") {
    //   return;
    // }
  
    // setPublishText("Publishing...");
    //setShowSnackbar(true);
    //setSnackbarText("Publishing...");

    try {
      const response = await fetch(DOMAIN + 'dashboard/publish', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        api_key: knowledge.api_key,
        body: JSON.stringify(knowledge)
      });

      console.log(JSON.stringify(knowledge))
  
      const responseData = await response.json();
      console.log(responseData);

      let errored = response.status !== 200 ? true : false;

      return {error: errored, text: errored ? responseData.error + " Try generating a new dashboard link in-game with /cbp dashboard." : "Published!"};
    } catch (error) {
      return {error: true, text: "You are either publishing too often, or an unknown error occurred. Please try again in a few minutes."};
    }
  }
  

export { parseDoc, convertToKnowledgeFormat, handlePublish };